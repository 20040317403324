import React from 'react';
import {Box, BoxProps, Container} from '@chakra-ui/react';
import {MotionBox} from '../MotionBox/MotionBox';

interface Props extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
}
export const ComponentWrapper = ({children, ...props}: Props) => (
  <Box {...props}>
    <Container maxW="container.xl">
      <MotionBox
        initial={{opacity: 0, scale: 0.9}}
        animate={{opacity: 1, scale: 1}}
        exit={{opacity: 0, scale: 0.9}}>
        {children}
      </MotionBox>
    </Container>
  </Box>
);
