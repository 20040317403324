import {Box} from '@chakra-ui/layout';
import React, {Dispatch} from 'react';
import {BlogProps, BlogView} from './BlogView';
import {Spinner, Center} from '@chakra-ui/react';
import {useBlogContent} from '../../api/getBlogContent';
import {ComponentWrapper} from '../ComponentWrapper';
import {useLanguageChange} from '../../utils/useLanguageChange';
import {useDispatch} from 'react-redux';
import {addOrUpdateIntent} from '../../lib/context/actionCreators';
import {linkState} from '../../utils/linkState';
import {Action} from '../../lib/context/types';

interface Props {
  id?: string;
  tags?: string[];
  individualItem?: boolean;
  lazyLoading: boolean;
}

export const BlogController = ({
  id,
  tags,
  individualItem = false,
  lazyLoading,
}: Props) => {
  const dispatch: Dispatch<Action> = useDispatch();

  if (individualItem && !tags) {
    console.error(
      'Tags must be specified when using component as an individual item',
    );
  }
  const {data, isLoading, isFetching, refetch} = useBlogContent(
    individualItem ? {tags} : {id},
  );

  useLanguageChange(refetch);

  const onClickContentLink = (blogData: BlogProps) => () => {
    if (individualItem) {
      blogData.relevantServices &&
        blogData.relevantServices.forEach((service) => {
          dispatch(addOrUpdateIntent(linkState(service.id, 50, 'action')));
        });
    }
  };

  return (
    <Box as="section" mb={[12, null, 14]} bg="blogDetailBackgroundColor">
      {isLoading || isFetching ? (
        <ComponentWrapper color="introColor" pl={1}>
          <Center>
            <Spinner
              mt={10}
              emptyColor="gray.200"
              thickness="4px"
              speed="0.7s"
              size="xl"
              color="primary"
            />
          </Center>
        </ComponentWrapper>
      ) : data ? (
        <BlogView
          lazyLoading={lazyLoading}
          onClickContentLink={onClickContentLink(data)}
          imageUrl={data.imageUrl}
          imageAlt={data.imageAlt}
          publishDate={data.publishDate}
          title={data.title}
          intro={data.intro}
          body={data.body}
          relevantServices={data.relevantServices}
          individualItem={individualItem}
        />
      ) : null}
    </Box>
  );
};
