import {Box, Flex, Text, Image, Heading} from '@chakra-ui/react';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RelevantService} from '../../api/types';
import {languageSelector} from '../../lib/context/selectors';
import {ComponentWrapper} from '../ComponentWrapper';
import {HtmlContent} from '../_shared/HtmlContent';
import {Dispatch} from 'redux';
import {Action} from '../../lib/context/types';
import {useDispatch} from 'react-redux';
import {addOrUpdateIntent} from '../../lib/context/actionCreators';
import {DisclaimerController} from '../Disclaimer/DisclaimerController';
import {BackButton} from '../_shared/BackButton/BackButton';

export interface BlogProps {
  imageUrl: string;
  publishDate: string;
  title: string;
  intro: string;
  body: string;
  individualItem: boolean;
  relevantServices: RelevantService[];
  showTranslationDisclaimer?: boolean;
  onClickContentLink: () => void;
  lazyLoading: boolean;
  imageAlt: string;
}

export const BlogView = ({
  lazyLoading,
  imageUrl,
  imageAlt,
  publishDate,
  title,
  intro,
  body,
  relevantServices,
  individualItem,
  showTranslationDisclaimer,
  onClickContentLink,
}: BlogProps) => {
  const {t} = useTranslation();
  const language = useSelector(languageSelector);
  const dispatch: Dispatch<Action> = useDispatch();

  const date = new Date(publishDate);
  let formattedDate = date.toLocaleString(language!!, {
    weekday: 'long',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  formattedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  useEffect(() => {
    if (!individualItem) {
      relevantServices &&
        relevantServices.forEach((service) => {
          dispatch(
            addOrUpdateIntent({
              axis: 'state',
              label: 'intent',
              intent: 'interest',
              value: service.id,
              score: 100,
              single: false,
              mutable: true,
              removable: true,
            }),
          );
        });
    }
  }, [dispatch, individualItem, relevantServices]);
  return (
    <>
      {imageUrl && (
        <Image
          maxHeight={500}
          height={[200, 300, null, null, 400, 500]}
          width="100%"
          src={imageUrl}
          alt={imageAlt ?? ''}
          objectFit="cover"
          data-cy="blogHeaderImage"
          loading={lazyLoading ? 'lazy' : 'eager'}
        />
      )}
      {showTranslationDisclaimer && (
        <DisclaimerController closable message={t('translation_disclaimer')} />
      )}
      <ComponentWrapper>
        <Flex
          alignItems="center"
          mt="7"
          mb={individualItem ? ['7', '7', '10'] : ['14', '14', '20']}
          transform={[
            null,
            null,
            null,
            null,
            individualItem ? null : 'translateX(-80px)',
          ]}>
          {!individualItem && (
            <BackButton
              dataCy="blogBackButton"
              gaProps={{type: 'blog', title}}
            />
          )}
          <Box ms={individualItem ? 0 : 6} mt="1" data-cy="blogPublishdate">
            <Text mb={0} fontWeight="normal">
              {t('services.published_on', {date: null})}
            </Text>
            <Text mb={0}>{formattedDate}</Text>
          </Box>
        </Flex>
        <Box data-cy="blogContent" maxW={[null, null, null, '75%']}>
          <Heading as="h1" variant="h1">
            {title}
          </Heading>
          <Text textStyle="intro">{intro}</Text>
          <HtmlContent
            lazyLoading={lazyLoading}
            data={body}
            clickHandler={onClickContentLink}
          />
        </Box>
      </ComponentWrapper>
    </>
  );
};
