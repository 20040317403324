import React from 'react';
import {breakpoints} from '../../../theme/themes/default';
import {Box} from '@chakra-ui/layout';
import styled from '@emotion/styled';
import {parseHtmlString} from '../../../utils/parseHtmlString';
import {base} from '../../../theme/components/Base';
import {useTheme} from '../../../hooks/useTheme';
import {CSSObject} from '@chakra-ui/react';

export const HtmlContent = ({
  data,
  clickHandler,
  lazyLoading,
  customLinkStyles,
}: {
  data: string;
  clickHandler?(): void;
  lazyLoading: boolean;
  customLinkStyles?: CSSObject;
}) => {
  const theme = useTheme();
  if (!data) {
    return null;
  }

  const styles = {
    ...base.headings,
  };

  const StyledRichText = styled(Box)`
    ul,
    ol {
      margin-left: 16px;

      li {
        margin-bottom: 20px;
      }
    }
    p,
    ol,
    ul,
    img {
      height: auto;
    }
    ,
    image {
      + h2 {
        margin-top: ${theme.space[14]};
        @media (min-width: ${breakpoints.lg}) {
          margin-top: ${theme.space[10]};
        }
      }
      + h3 {
        margin-top: ${theme.space[8]};
        @media (min-width: ${breakpoints.lg}) {
          margin-top: ${theme.space[10]};
        }
      }
      + h4 {
        margin-top: ${theme.space[10]};
      }
    }
  `;

  return (
    <StyledRichText sx={styles}>
      <Box>
        {parseHtmlString(data, lazyLoading, clickHandler, customLinkStyles)}
      </Box>
    </StyledRichText>
  );
};
