import getConfig from 'next/config';
import {QueryKey, useQuery, UseQueryOptions} from 'react-query';
import {useSelector} from 'react-redux';
import {BlogProps} from '../components/Blog/BlogView';
import {languageSelector} from '../lib/context/selectors';
import {fapper} from '../utils/fapper';

const {publicRuntimeConfig} = getConfig();

export type BlogContentResult = {contentItem: BlogProps};
export type BlogContentResultTag = {contentItems: BlogProps[]};

const getBlogContent = async (
  search: {id?: string; tags?: string[]},
  language: string,
): Promise<BlogProps> => {
  if (search.id) {
    const result: BlogContentResult = await fapper.get(
      `${publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API}?culture=${language}&slug=${search.id}&type=blog`,
    );
    return result.contentItem;
  } else {
    const result: BlogContentResultTag = await fapper.get(
      `${
        publicRuntimeConfig.NEXT_PUBLIC_UMBRACO_API
      }?culture=${language}&tags=${search.tags?.join(';')}&type=blog`,
    );
    return result.contentItems[0];
  }
};

const useBlogContent = (
  search: {id?: string; tags?: string[]},
  options: UseQueryOptions<BlogProps, unknown, BlogProps, QueryKey> = {
    refetchOnWindowFocus: false,
  },
) => {
  let queryId = '';
  if (search.id && search.tags) {
    console.error('Either provide id or tags, but not both');
  }
  if (search.id) {
    queryId = search.id;
  } else if (search.tags) {
    queryId = search.tags.join(',');
  }
  const language = useSelector(languageSelector);
  return useQuery<BlogProps>(
    ['BlogContent', queryId],
    () => getBlogContent(search, language!!),
    options,
  );
};

export {useBlogContent, getBlogContent};
