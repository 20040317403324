import React, {useState} from 'react';
import {DisclaimerView} from './DisclaimerView';

interface Props {
  message: string;
  closable?: boolean;
}
export const DisclaimerController = ({message, closable}: Props) => {
  const [visible, setVisible] = useState(true);
  return (
    <DisclaimerView
      visible={visible}
      message={message}
      closable={closable}
      onClose={() => setVisible(false)}
    />
  );
};
