import {CloseIcon, InfoOutlineIcon} from '@chakra-ui/icons';
import {Box, Flex, Collapse, Text, useTheme} from '@chakra-ui/react';
import React from 'react';
import {DefaultTheme} from '../../theme/themes/default';
import {ComponentWrapper} from '../ComponentWrapper';

interface Props {
  visible: boolean;
  message: string;
  closable?: boolean;
  onClose: () => void;
}

export const DisclaimerView = ({
  visible,
  message,
  closable = true,
  onClose,
}: Props) => {
  const theme = useTheme<DefaultTheme>();
  return (
    <Collapse in={visible}>
      <Flex
        flexDirection={['row', 'row']}
        backgroundColor="secondaryBackground"
        p="4">
        <Box flex={1}>
          <ComponentWrapper>
            <Flex
              flexDirection={['row', 'row']}
              justifyContent="center"
              paddingX="2">
              <InfoOutlineIcon
                w="6"
                h="6"
                color={theme.colors.bodyText}
                marginTop="2"
                marginRight="2"
              />
              <Box flex={1} marginTop="1">
                <Text p="1" as="span">
                  {message}
                </Text>
              </Box>
            </Flex>
          </ComponentWrapper>
        </Box>
        {closable && (
          <Box>
            <CloseIcon
              w="3.5"
              h="3.5"
              color={theme.colors.bodyText}
              onClick={onClose}
            />
          </Box>
        )}
      </Flex>
    </Collapse>
  );
};
